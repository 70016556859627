import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@repo/ui/card'
import { cn } from '@repo/ui/lib/utils'
import type { status as StatusCodes } from 'http-status'
import { useTranslations } from 'next-intl'

export interface StatusMessageProps {
    status: keyof typeof StatusCodes & number
    title?: string | null

    children?: React.ReactNode
}

export default function StatusMessage({ status, title, children }: StatusMessageProps) {
    if (title === undefined) {
        const t = useTranslations('status')
        title = t(status.toString())
    }

    return (
        <Card className='w-fit max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen1-2xl h-fit'>
            <CardHeader
                className={cn(
                    'flex flex-row items-center justify-start',
                    title?.length ? 'divide-x-2 divide-solid divide-secondary-foreground' : undefined
                )}
            >
                <CardTitle className={cn('text-xl font-semibold', title?.length ? 'pr-2' : undefined)}>
                    {status}
                </CardTitle>

                {title?.length && <CardDescription className='pl-2'>{title}</CardDescription>}
            </CardHeader>

            <CardContent className='w-full h-full flex flex-col items-center justify-center justify-items-center'>
                {children}
            </CardContent>
        </Card>
    )
}

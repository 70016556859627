'use client'

import { CardContent, CardDescription, CardFooter } from '@repo/ui/card'
import RootLayout from 'components/layout/root'
import { status as StatusCodes } from 'http-status'
import StatusMessage from 'components/card/message/status-message'
import { Button } from '@repo/ui/button'
import { RotateCcwIcon } from 'lucide-react'
import { useTranslations } from 'next-intl'
import ThemeToggle from 'components/theme/theme-toggle'
import LocaleSelector from 'components/locale/locale-selector'

export default function ErrorPage({
    error: { name, digest, message, ...error },
    reset,
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    const t = useTranslations()

    let status: keyof typeof StatusCodes = StatusCodes.INTERNAL_SERVER_ERROR
    const ndigest = digest && Number.parseInt(digest)
    if (ndigest && ndigest in StatusCodes) {
        digest = undefined
        status = ndigest as typeof status
    }

    return (
        <RootLayout>
            <div className='relative flex min-h-screen flex-col bg-background'>
                <header className='sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60'>
                    <div className='container flex h-14 max-w-screen-2xl items-center'>
                        <div className='flex flex-1 items-center justify-between space-x-2 md:justify-end'>
                            <nav className='flex items-center space-x-2'>
                                <ThemeToggle variant='ghost' />
                                <LocaleSelector variant='ghost' />
                            </nav>
                        </div>
                    </div>
                </header>

                <main className='flex-1'>
                    <div className='min-h-screen flex flex-col justify-center items-start md:items-center p-8'>
                        <StatusMessage status={status}>
                            <CardContent className='flex flex-col justify-items-center justify-center items-center space-y-4'>
                                <CardDescription className='text-md'>
                                    {t(`message.${status.toString()}`, {
                                        value: message ?? StatusCodes[`${status}_MESSAGE`],
                                    })}
                                </CardDescription>

                                <Button className='w-fit' onClick={reset}>
                                    <RotateCcwIcon className='w-4 h-4' />
                                    {t('error.try_again')}
                                </Button>
                            </CardContent>

                            {digest && (
                                <CardFooter>
                                    <CardDescription className='text-sm italic'>{`#${digest}`}</CardDescription>
                                </CardFooter>
                            )}
                        </StatusMessage>
                    </div>
                </main>
            </div>
        </RootLayout>
    )
}
